import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
})
export class MyAccountComponent implements OnInit, OnDestroy {
  usergetData: any;
  payerObject: any;
  defaultB2BUnitObject: any;
  userAccountgetData: any;
  accountId: any;
  userAccountDataSubscriber: any;

  constructor(
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef
  ) {}

  getUserAccountData(userId): void {
    this.userAccountDataSubscriber = this.pfService.userAccountData.subscribe(
      (userData) => {
        if (userData?.res && userData?.res?.length !== 0) {
          this.usergetData = userData?.res?.defaultB2BUnit?.billingAddress;

          this.payerObject = userData?.res?.defaultB2BUnit?.payerAddress;
          this.defaultB2BUnitObject = userData?.res?.defaultB2BUnit;

          this.ref.detectChanges();
        }
        this.userAccountDataSubscriber?.unsubscribe();
      }
    );
  }

  ngOnDestroy() {
    this.userAccountDataSubscriber?.unsubscribe();
  }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
      }
    });
  }
}
