<div class="custom-product-card">
  <div class="custom-card-header">
    <section class="text-center">
      <a *ngIf="productInfo?.images; else noImage" (click)="itemSelected(productInfo)">
        <cx-media class="cx-product-image" [container]="productInfo.images?.PRIMARY" format="product"
          [alt]="productInfo.summary"></cx-media>
      </a>
      <ng-template #noImage>
        <a (click)="itemSelected(productInfo)">
          <img class="image-set-in-card product-img" [src]="fallbackImage" alt="" />
        </a>
      </ng-template>
    </section>

    <a class="c-linked-heading" (click)="itemSelected(productInfo)">
      <h3 class="c-heading c-heading-h3 my-2">{{ productInfo.name }}</h3>
    </a>
  </div>
  <div class="py-2">
    <div class="row" *ngIf="productInfo?.batchInfo?.batchNumber">
      <p class="custom-tab-title col-6 mb-1 text-left"> {{ "plp.batchNumber" | cxTranslate }} </p>
      <p class="batchinfo-p col-6 mb-1 text-right"><b>{{ productInfo?.batchInfo?.batchNumber }}</b></p>
    </div>
    <div class="row" *ngIf="productInfo?.batchInfo?.expiryDate">
      <p class="custom-tab-title col-6 mt-0 mb-1 text-left"> {{ "plp.expiryDate" | cxTranslate }} </p>
      <p class="batchinfo-p col-6 mt-0 mb-1 text-right"><b>{{ productInfo?.batchInfo?.expiryDate }}</b></p>
    </div>
    <div class="row" *ngIf="productInfo?.sapEan">
      <p class="text-fixed-legal-copy col-6 mt-0 mb-1 text-left"> {{ "plp.productNumber" | cxTranslate }} </p>
      <p class="text-fixed-legal-copy col-6 mt-0 mb-1 text-right"><b>{{ productInfo?.sapEan }}</b></p>
    </div>
  </div>
  <!-- PDP section, do not move -->
  <section class="custom-card-body" *ngIf="!iconDisplay">
    <p class="custom-tab-p display-type text-fixed-medium" [innerHTML]="
        sanitizer.bypassSecurityTrustHtml(description | slice : 0 : 300)
      "></p>
    <a class="show-more display-type" *ngIf="description?.length > 300"
      [routerLink]="['/product', productInfo.code, productInfo.code]">...Show More</a>
  </section>
  <!-- PDP section end, do not move -->

  <div *ngIf="userDetails !== undefined">
    <div>
      <div *ngIf="!productInfo.flu">
        <!-- Actual Potency -->
        <div class="row" *ngIf="productInfo?.actualPotency">
          <p class="col-7 mb-1 text-left"> {{ "plp.actualPotency" | cxTranslate }} </p>
          <p class="col-5 mb-1 text-right"><b> {{ productInfo?.actualPotency }} </b></p>
        </div>
        <!-- IU Price -->
        <div class="row" *ngIf="productInfo?.actualPotency">
          <p class="col-6 mb-1 text-left"> {{ "plp.iuPrice" | cxTranslate }} </p>
          <p class="col-6 mb-1 text-right"><b>
              <span *ngIf=" productInfo?.iuPrice == undefined || productInfo?.iuPrice?.value === undefined">-</span>
              <span *ngIf=" productInfo?.iuPrice && productInfo?.iuPrice?.value !== undefined">
                {{ productInfo?.iuPrice?.value | currency }}
              </span>
            </b></p>
        </div>
        <!-- Stock Indication -->
        <div *ngIf="productInfo?.showStockAvailability" class="d-flex justify-content-between mt-2">
          <div>
            <p>{{ "plp.availability" | cxTranslate }}</p>
          </div>
          <div class="row">
            <p [ngClass]="{'col-8': productInfo?.stockAvailableStatus == 'LOW',
                             'col-9': productInfo?.stockAvailableStatus != 'LOW'}">
              {{ productInfo?.stockAvailableStatus }} </p>
            <div class=" c-round-chip" [ngClass]="{
              'c-round-chip-limited': productInfo?.stockAvailableStatus == 'LIMITED',
              'c-round-chip-low': productInfo?.stockAvailableStatus == 'LOW',
              'c-round-chip-normal': productInfo?.stockAvailableStatus == 'NORMAL'
            }" (mouseenter)="p1.open()" [ngbPopover]="popOverBatch" #p1="ngbPopover" placement="top"
              (mouseleave)="p1.close()">
            </div>
          </div>

          <ng-template #popOverBatch>
            <div class="c-popover-black">
              <p *ngIf="productInfo?.stockAvailableStatus == 'LOW'">
                Low stock<br /><span>Stock is Limited</span>
              </p>
              <p *ngIf="productInfo?.stockAvailableStatus == 'NORMAL'">
                Normal stock<br /><span>Stock is available</span>
              </p>
              <p *ngIf="
                  productInfo?.stockAvailableStatus == 'LIMITED' &&
                  productInfo?.allowLimitedStockProducts !== true
                ">
                Stock Unavailable<br /><span>Please call Customer Support</span>
              </p>
              <p *ngIf="
                  productInfo?.stockAvailableStatus == 'LIMITED' &&
                  productInfo?.allowLimitedStockProducts == true
                ">
                Stock is very Limited <br />
              </p>
            </div>
          </ng-template>
        </div>
        <!-- Unit price -->
        <div class="d-flex justify-content-between flex-wrap-reverse mb-2">
          <div>
            <h4 class="d-block" *ngIf="productInfo?.actualPotency; else notActualPotency">
              {{ "plp.unitPrice" | cxTranslate }}
            </h4>
            <ng-template #notActualPotency>
              <h4 class="d-block">
                {{ "plp.yourPrice" | cxTranslate }}
              </h4>
            </ng-template>
            <h4 class="font-bold">
              <span *ngIf="
                      productInfo?.price == undefined ||
                      productInfo?.price?.value === undefined
                    ">-</span>
              <span *ngIf="
                      productInfo?.price &&
                      productInfo?.price?.value !== undefined
                    ">
                {{ productInfo?.price?.value | currency }}
              </span>
            </h4>
          </div>

          <div class="units" *ngIf="!productInfo?.discontinued && productInfo?.available">
            <p class="mb-2 text-center font-weight-bold">
              {{ "plp.units" | cxTranslate }}
            </p>
            <div class="unit-area d-flex flex-nowrap align-items-center justify-content-center">
              <span id="{{ 'unitMinus' + productInfo?.code }}" class="d-none"></span>
              <span id="{{ 'unitMinusDisable' + productInfo?.code }}" class="d-none"></span>
              <span id="{{ 'unitPlus' + productInfo?.code }}" class="d-none"></span>
              <span id="{{ 'unitPlusDisable' + productInfo?.code }}" class="d-none"></span>
              <button id="{{ 'minusIcon' + productInfo.code }}" class="c-count-change-btn disabled" aria-hidden="true"
                (click)="
                      performQuantityComputation(
                        2,
                        productInfo.code,
                        productInfo.quantityPerUnit
                      )
                    ">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </button>
              <input type="number" class="prod-quantity text-center" value="1" maxlength="4" (keyup)="
                      performQuantityComputation(
                        null,
                        productInfo.code,
                        productInfo.quantityPerUnit
                      )
                    " onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                id="{{ 'prodQuantity' + productInfo.code }}" />
              <button id="{{ 'plusIcon' + productInfo.code }}" class="c-count-change-btn" aria-hidden="true" (click)="
                      performQuantityComputation(
                        1,
                        productInfo.code,
                        productInfo.quantityPerUnit
                      )
                    ">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <section *ngIf="!productInfo?.discontinued && productInfo?.available">
          <section *ngIf="productInfo.sapAllocation">
            <b class="prod-allocation">{{
              "plp.productAllocation" | cxTranslate
              }}</b>
            <p class="allocation-p">
              {{ "plp.allocationDetails" | cxTranslate }}
            </p>
          </section>
        </section>
      </div>

      <section *ngIf="productInfo.flu">
        <section>
          <p *ngIf="
              productInfo.flu &&
              productInfo.available &&
              !productInfo.discontinued
            " class="contact-info m0">
            {{ "plp.productAllocationInfo1" | cxTranslate
            }}<b> {{ "plp.emergencycontact" | cxTranslate }}</b>{{ "plp.productAllocationInfo2" | cxTranslate }}
          </p>
          <button type="button" class="c-button c-button--primary w-100" *ngIf="
              productInfo.flu &&
              productInfo.available &&
              !productInfo.discontinued
            " (click)="addToCart(productInfo?.code, p2)">
            <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
            <span *ngIf="buttonName === 'Add to cart'">{{
              "addToCartItems.addCart" | cxTranslate
              }}</span>
            <span *ngIf="buttonName === 'Login to order'">{{
              "addToCartItems.loginToOrder" | cxTranslate
              }}</span>
            <span *ngIf="buttonName === 'Reserve now'">{{
              "addToCartItems.reserveNow" | cxTranslate
              }}</span>
          </button>
        </section>
      </section>
      <div class="custom-card-cta">
        <span *ngIf="
            productInfo?.stockAvailableStatus == 'LIMITED' &&
            productInfo?.allowLimitedStockProducts !== true
          " class="c-text color-error" disabled>
          {{ "plp.unavailableToOrderText" | cxTranslate }}
        </span>

        <button type="button" class="c-button c-button--primary" *ngIf="
            !productInfo?.discontinued &&
            productInfo?.available &&
            productInfo?.price?.value != undefined &&
            productInfo?.price?.value != 0 &&
            productInfo?.price?.value != 0.0 &&
            (productInfo?.stockAvailableStatus !== 'LIMITED' ||
              (productInfo?.stockAvailableStatus == 'LIMITED' &&
                productInfo?.allowLimitedStockProducts == true))
          " [ngbPopover]="popTitleSuccess" placement="bottom" triggers="manual" #p2="ngbPopover"
          popoverClass="savesuccess-msg addToCart" container="body" placement="bottom" [closeDelay]="500"
          (click)="addToCart(productInfo?.code, p2)" [disabled]="
            productInfo?.stockAvailableStatus == 'LIMITED' &&
            productInfo?.allowLimitedStockProducts !== true
          ">
          <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
          <span *ngIf="buttonName === 'Add to cart'">{{
            "addToCartItems.addCart" | cxTranslate
            }}</span>
          <div *ngIf="
              buttonName === 'Add to cart' &&
              productInfo?.stockAvailableStatus == 'LIMITED'
            " style="
              background-color: transparent;
              width: 100%;
              height: 100%;
              position: absolute;
            " (mouseenter)="p2.open()" [ngbPopover]="popOverLimited" #p2="ngbPopover" placement="top"
            (mouseleave)="p2.close()"></div>
          <span *ngIf="buttonName === 'Login to order'">{{
            "addToCartItems.loginToOrder" | cxTranslate
            }}</span>
          <span *ngIf="buttonName === 'Reserve now'">{{
            "addToCartItems.reserveNow" | cxTranslate
            }}</span>
        </button>
        <ng-template #popTitleSuccess>
          <div class="cart-value-added">
            <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
              alt="success" />
            <span class="confirmation-itemsadded" *ngIf="!flagRemoved; else NA">{{ itemAddedQuantity }}
              {{ "addToCartItems.addEntrySuccess" | cxTranslate }}</span>
            <ng-template #NA>
              <span class="confirmation-itemsadded">{{ itemAddedQuantity }}
                {{ "addToCartItems.updateEntrySuccess" | cxTranslate }}</span>
            </ng-template>
          </div>
        </ng-template>

        <button *ngIf="
            (!productInfo?.price ||
              productInfo?.price?.value == undefined ||
              productInfo?.price?.value == 0 ||
              productInfo?.price?.value == 0.0) &&
            !(
              productInfo?.stockAvailableStatus == 'LIMITED' &&
              productInfo?.allowLimitedStockProducts !== true
            )
          " class="c-button c-button--primary" disabled>
          {{ "plp.unavailableToOrder" | cxTranslate }}
        </button>
        <ng-template #popOverLimited>
          <div class="c-popover-black">
            <p *ngIf="productInfo?.stockAvailableStatus == 'LOW'">
              Low stock<br /><span>Stock is Limited</span>
            </p>
            <p *ngIf="productInfo?.stockAvailableStatus == 'NORMAL'">
              Normal stock<br /><span>Stock is available</span>
            </p>
            <p *ngIf="
                productInfo?.stockAvailableStatus == 'LIMITED' &&
                productInfo?.allowLimitedStockProducts !== true
              ">
              Stock Unavailable<br /><span>Please call Customer Support</span>
            </p>
            <p *ngIf="
                productInfo?.stockAvailableStatus == 'LIMITED' &&
                productInfo?.allowLimitedStockProducts == true
              ">
              Stock is very Limited <br />
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <section *ngIf="userDetails == undefined">
    <button type="button" class="c-button c-button--primary w-100" (click)="goToLogin()">
      <span *ngIf="buttonName === 'Add to cart'">{{
        "addToCartItems.addCart" | cxTranslate
        }}</span>
      <span *ngIf="buttonName === 'Login to order'">{{
        "addToCartItems.loginToOrder" | cxTranslate
        }}</span>
      <span *ngIf="buttonName === 'Reserve now'">{{
        "addToCartItems.reserveNow" | cxTranslate
        }}</span>
    </button>
  </section>
</div>