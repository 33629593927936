import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { CustomSearchService } from 'src/app/search-box/search-box/custom-search.service';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit, OnDestroy {
  @ViewChild('a') accordion: NgbAccordion;
  faq = [];
  openById = {};
  expandAll = true;
  FaqDataSubscription: any;
  filteredItems = [];
  searchValue: string = '';
  filterOption: string = 'all';
  helpValSub: Subscription;
  helpVal: any;
  searchHelpList: any;

  constructor(
    private readonly faqService: FaqService,
    private readonly cd: ChangeDetectorRef,
    private readonly customSearchService: CustomSearchService
  ) {}

  ngOnInit() {
    this.helpValSub = this.customSearchService.$helpValue.subscribe((res) => {
      this.helpVal = res;
      if (res && res != '' && res != null) {
        this.populateFaq();
      }
    });
    if (!this.helpVal || this.helpVal == null) {
      this.faqService.getFAQ();
      this.FaqDataSubscription = this.faqService.faqList$.subscribe((res) => {
        this.filteredItems = res;
        this.cd.detectChanges();
      });
    }
  }
  removeSearched(items) {
    let res = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].questions?.code != this.helpVal) {
        res.push(items[i]);
        return res;
      }
    }
  }
  populateFaq() {
    this.faqService.getFAQ();
    this.FaqDataSubscription = this.faqService.faqList$.subscribe((res) => {
      if (res) {
        this.faq = res;
        if (this.helpVal && this.helpVal != null && this.helpVal != '') {
          this.searchHelpList = this.faq.filter((f) =>
            f.questions.some((q) => q.code.includes(this.helpVal))
          );
          // Remove the search result topic from the main list
          this.filteredItems = this.faq.filter(
            (f) => !f.questions.some((q) => q.code.includes(this.helpVal))
          );
          this.cd.detectChanges();
          // Expand accordion Help topic results
          $('#' + this.helpVal).addClass('show');
          $('#' + this.helpVal)
            .parent()
            .find('.toggle-item button')
            .addClass('active');
        }
      }
    });
  }

  showItem(e): void {
    e.target.classList.contains('active')
      ? e.target.classList.remove('active')
      : e.target.classList.add('active');
    const itemChild = e.target.parentElement.parentElement.querySelector(
      '.item-child-container'
    );
    itemChild.classList.contains('show')
      ? itemChild.classList.remove('show')
      : itemChild.classList.add('show');
  }

  showAllChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.querySelectorAll('.item-child');

    targetItem.forEach((element) => {
      element.querySelector('.item-child-answer').classList.add('show');
      element.querySelector('.child-btn').classList.add('active');
    });
    e.target.classList.remove('show');
    e.target.nextSibling.classList.add('show');
  }
  hideAllChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.querySelectorAll('.item-child');
    targetItem.forEach((element) => {
      element.querySelector('.item-child-answer').classList.remove('show');
      element.querySelector('.child-btn').classList.remove('active');
    });
    e.target.classList.remove('show');
    e.target.previousSibling.classList.add('show');
  }

  toggleChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.parentElement.querySelector(
        '.item-child-answer'
      );
    if (targetItem.classList.contains('show')) {
      targetItem.classList.remove('show');
      e.target.classList.remove('active');
    } else {
      targetItem.classList.add('show');
      e.target.classList.add('active');
    }
  }

  ngOnDestroy() {
    this.customSearchService.updateHelpValue(null);
    this.FaqDataSubscription?.unsubscribe();
    this.helpValSub?.unsubscribe();
    this.searchHelpList = undefined;
  }

  searchByValue(items) {
    return items.filter((item) => {
      if (this.searchValue.trim() === '') {
        return true;
      } else {
        return item.item
          .toLowerCase()
          .includes(this.searchValue.trim().toLocaleLowerCase());
      }
    });
  }

  filterByOption(items) {
    return items.filter((item) => {
      if (this.filterOption === 'all' || this.filterOption === item.type) {
        return true;
      }
    });
  }
}
