import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { MakeAPaymentService } from 'src/app/custom-make-a-payment/make-a-payment.service';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { AddCardService } from './add-card.service';
@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
  url = 'https://angular.io/api/router/RouterLink';
  url1 =
    'https://cert-xiecomm.paymetric.com/DIeComm/View/Iframe/b10dcdf9-97b8-4668-97e5-398a1252fea3/015c94f4-3690-4583-b857-4b680a0d42c7/True';
  urlSafe: SafeResourceUrl;
  iframeUrl: any;

  accessTokenForIframe: any;
  defaultCard = false;
  isCheckboxChecked = false;
  sanitizedUrl: any;
  closeResult: any;
  iframeLoad = false;
  dummyUrl: SafeResourceUrl;
  @ViewChild('iframe') iframe: ElementRef;
  @Input() public isCheckbox;
  constructor(
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly sanitizer: DomSanitizer,

    public activeModal: NgbActiveModal,
    private readonly globalMessage: GlobalMessageService,
    private readonly addCardService: AddCardService,
    private readonly makeAPaymentService: MakeAPaymentService
  ) {}

  ngOnInit(): void {
    if (this.isCheckbox === false) {
      this.isCheckboxChecked = true;
    }
  }

  ngAfterViewInit(): void {
    this.loadScript();
    this.pfService.getIframeUrl();
    this.pfService.iframeUrl.subscribe((data) => {
      this.accessTokenForIframe = data.accessToken;

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        data.iframeUrl
      );
      this.dummyUrl = data.iframeUrl;
      this.iframeLoad = true;
    });
  }

  loadScript() {
    if (!window.document.getElementById('payment')) {
      const script = window.document.createElement('script');
      script.id = 'payment';
      script.type = 'text/javascript';
      script.src = './assets/scripts/payments.js';
      window.document.body.appendChild(script);
    }
  }
  onload() {
    window['$XIFrame'].onload({
      iFrameId: 'valid-for-data',
      targetUrl: this.dummyUrl,
      autosizeheight: true,
      autosizewidth: true,
      onSuccess: function (e) {},
      onError: function (e) {},
    });
  }
  GetStats(event) {
    this.defaultCard = event.currentTarget.checked;
    if (this.defaultCard) {
      this.isCheckboxChecked = true;
    }
  }
  saveCard(event) {
    this.isCheckboxChecked = event.currentTarget.checked;
  }

  onSubmitIFrame() {
    window['$XIFrame'].submit({
      iFrameId: 'valid-for-data',
      targetUrl: this.dummyUrl,
      autosizeheight: true,
      autosizewidth: true,

      onSuccess: (msg) => {
        const message = JSON.parse(msg);
        if (message && message.data.HasPassed) {
          this.addCardService
            .addCard({
              accessToken: this.accessTokenForIframe,
              defaultPaymentInfo: this.defaultCard,
              savePaymentDetails: this.isCheckboxChecked,
            })
            .subscribe((res) => {
              if (res && res.addPaymentDetails === true) {
                this.pfService.getAccountIdFromDb();

                this.addCardService.cardAdded.next(res);
                this.activeModal.close();
              } else {
                this.activeModal.close();
                window.scroll(0, 0);
                this.globalMessage.add(
                  res.errorMessage,
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }
            });
        }
      },
      onError: function (msg) {
        console.log(msg);
      },
    });
  }

  ngOnDestroy() {
    this.addCardService.cardAdded.next(false);
  }
}
