import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  ProductSearchAdapter,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  ActiveFacetsModule,
  FacetModule,
  ListNavigationModule,
  MediaModule,
  PaginationModule,
  ProductImagesModule,
} from '@spartacus/storefront';
import { FaqModule } from '../faq/faq.module';
import { customOccProductConfig } from '../shared/config/custom-prod.config';
import { CustomOccProductSearchAdapter } from './custom-occ-product-search.adapter';
import { CustomProductCardComponent } from './custom-product-card/custom-product-card.component';
import { CustomProductPageComponent } from './custom-product-page/custom-product-page.component';
import { CustomProductRefinementComponent } from './custom-product-refinement/custom-product-refinement.component';
import { FaqListComponent } from './faq-list/faq-list.component';
import { ProdHelpTabComponent } from './prod-help-tab/prod-help-tab.component';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';

@NgModule({
  declarations: [
    CustomProductPageComponent,
    CustomProductRefinementComponent,
    CustomProductCardComponent,
    TabsComponent,
    TabComponent,
    ProdHelpTabComponent,
    FaqListComponent,
  ],
  exports: [CustomProductCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ListNavigationModule,
    FacetModule,
    PaginationModule,
    ProductImagesModule,
    ActiveFacetsModule,
    MediaModule,
    RouterModule,
    I18nModule,
    NgSelectModule,
    FaqModule,
    ConfigModule.withConfig({
      pagination: {
        addStart: false,
        addEnd: false,
        addPrevious: true,
        addNext: true,
        nextLabel: '>',
        previousLabel: '<',
        addFirst: false,
        addLast: false,
        addDots: false,
        rangeCount: false,
      },

      cmsComponents: {
        ProductGridComponent: {
          component: CustomProductPageComponent,
          guards: [AuthGuard],
        },
        SearchResultsGridComponent: {
          component: CustomProductPageComponent,
          guards: [AuthGuard],
        },
        ProductRefinementComponent: {
          component: CustomProductRefinementComponent,
          guards: [AuthGuard],
        },
      },
      view: {
        defaultPageSize: 18,
      },
    } as CmsConfig),
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
    {
      provide: ProductSearchAdapter,
      useClass: CustomOccProductSearchAdapter,
    },
    provideDefaultConfig(customOccProductConfig),
  ],
})
export class CustomProdPgModule {}
