import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OccEndpointsService, RoutingService,LanguageService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';
import { CheckoutService } from 'src/app/shared/modules/checkout/checkout.service';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomPdpService } from 'src/app/custom-pdp/custom-pdp.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';
@Component({
  selector: 'app-flu-confirmation',
  templateUrl: './flu-confirmation.component.html',
  styleUrls: ['./flu-confirmation.component.scss']
})
export class FluConfirmationComponent implements OnInit,OnDestroy {
  productEntries: any = [];
  totalProducts: any = 0;
  totalDoses = 0;
  standardCartDetails: any = [];
  infContact: any;
  billingAddress: any;
  soldToAddress: any;
  paymentType: any;
  estTotal: any;
  userId: any;
  header = 'ViewCart';
  paymentsArray: any = [];
  duplicateCartProdArray: any = [];
  updatedProductsInCartArray: any = [];
  chosenPaymentMethod = 1;
  selectedHeader: any = 'Checkout';
  getuserData: any;
  usergetDataObj: any;
  userAccountgetData: any;
  selectedData: any;
  userData: any = [];
  fluProductsList = [];
  influenzaChildCartId: any;
  btnText: any = 'Proceed to Checkout';
  activeCardId: any;
  showDatePicker = false;
  minDate: Date;
  deliveryDate: Date;
  deliveryDateFormat = '';
  deliveryCrossIcon = false;
  dateIsRequired= false;
  showEmptyCart = false;
  entriesForPopup:any=[];
  fallbackImage = '../../assets/i18n-assets/sanofishoppe_missing_product.png';
  orderNo: string;
  dataForFluConfirmation:any=[];
  orderDate:any;
  orderId:any;
  purchaseOrderNumber:any;
  baseUrl: any;
  activeSeasonId:any;
  estimatedDeliveryDate: any='';
  languageUsed: string;
  freightProduct = false;
  userAccountDataSubscriber: any;
  userAccountSubscriber: any;
  userAccountDetailsSubscriber: any;
  userAccountAddtoCartServiceSubscriber: any;
  constructor(private readonly influenzaService: CustomInfluenzaService,
    private readonly ref: ChangeDetectorRef, private readonly checkoutService: CheckoutService,
    private readonly router: RoutingService, private readonly addToCartService: CustomAddToCartService,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly modalService: NgbModal,
    private readonly user:UserAccountFacade,
    private readonly sharedService:SharedService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly pdpService: CustomPdpService,
    private readonly languageService:LanguageService) {
      this.baseUrl = this.occEndPoint.getBaseUrl({
        baseUrl: true,
        prefix: false,
        baseSite: false,
      });
    }

  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {

      this.languageUsed=langRes;
      this.ref.detectChanges();
    });
      const data=this.sharedService.getSessionStorage('orderDataForinlfuenza');
      if(data){
        this.dataForFluConfirmation=[];
        this.dataForFluConfirmation.push(data);
        if(this.dataForFluConfirmation){
          this.dataForFluConfirmation.forEach((element,index) => {
            this.orderDate= element?.created;
            this.orderId=element?.code;
            this.infContact = element?.orderContact;
            this.purchaseOrderNumber=element?.purchaseOrderNumber;
            this.paymentType = element?.paymentType?.displayName;
            this.estTotal = element?.totalPriceWithTax?.value;
            this.productEntries = element?.entries;
            this.activeSeasonId = element?.seasonId;
            this.selectedData=element?.shippingAddress;
            if(this.productEntries){
              this.addProductEntries();
            }

        this.ref.detectChanges();

        });

        }
      }


    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userId = res?.userName;
        this.getUserAccountData(this.userId);
        this.gettingAccountDetails();
      }
    });


  }
  ngOnDestroy(){
    this.userAccountSubscriber?.unsubscribe();
    this.userAccountDataSubscriber?.unsubscribe();
    this.userAccountDetailsSubscriber?.unsubscribe();
    this.userAccountAddtoCartServiceSubscriber?.unsubscribe();
  }
  addProductEntries() {
    this.productEntries?.forEach((elem, index) => {

      if(elem?.estimatedDeliveryDate && elem?.product?.onsn==='ON'){
        this.estimatedDeliveryDate=elem?.estimatedDeliveryDate;
        }
      if(elem?.freightProduct){
          this.freightProduct=elem?.freightProduct;
     }
      this.totalProducts += parseInt(elem?.quantity);
      const a = parseInt(elem?.quantity);
      const b = parseInt(elem?.product?.quantityPerUnit);
      this.totalDoses = this.totalDoses + (a * b);
      let thumbNailImg = '';
      if (elem?.product?.images) {
        thumbNailImg = elem?.product?.images[2]?.url;
      }
      elem['thumbNailImg'] = thumbNailImg;


    });
  }
  backToDashBoard(){
    this.router.goByUrl('/influenza/dashboard');
  }


  gettingAccountDetails() {
    this.addToCartService.getUserAccountDataFromDb(this.userId);
  this.userAccountSubscriber=  this.addToCartService.userAccountData.subscribe((userData) => {
      if (
        userData.res != null &&
        userData.res !== undefined &&
        userData.res?.length !== 0
      ) {
        const usergetDataObj = userData?.res?.defaultB2BUnit;
        const accountId = usergetDataObj?.uid;
        this.addToCartService.getUserAccountDetailsDataFromDb(
          this.userId,
          accountId
        );
      }
      this.userAccountSubscriber?.unsubscribe();
    });
    this.userAccountAddtoCartServiceSubscriber=this.addToCartService.userAccountDetails.subscribe((userDataget) => {
      this.getuserData = userDataget?.res?.shippingAddresses;
      this.billingAddress = userDataget?.res?.billingAddress;
      this.soldToAddress = userDataget?.res?.soldToAddress;
      this.userAccountAddtoCartServiceSubscriber?.unsubscribe();
      this.ref.detectChanges();
    });
  }

  downloadAsExcelFlu(){
    const dataForExcel=[];

    this.productEntries?.forEach((prodData,index)=>{
      const obj={
        'Product ID':prodData?.product?.code,
        'Qty(unit/doses)':prodData?.quantity+'/'+ (prodData?.product?.quantityPerUnit*prodData?.quantity),
        'Product Name':prodData?.product?.name,
        'Bill to Location':this.billingAddress?.unitID,
        'Ship to Location':this.soldToAddress?.unitID
      };
      dataForExcel.push(obj);
    });
    this.influenzaService.downloadXls(dataForExcel,'Flu order confirmation');
  }
  // ============================Loc Strip Methods=======================
  getUserAccountData(userId): void {
    this.pfService.getUserAccountDataFromDb(userId);
   this.userAccountDataSubscriber= this.pfService.userAccountData.subscribe((userData) => {
      if (userData.res != null && userData.res !== undefined && userData.res?.length !== 0) {
        this.usergetDataObj = userData.res.defaultB2BUnit;
        const accountId = this.usergetDataObj.uid;
        this.pfService.getUserAccountDetailsDataFromDb(userId, accountId);
        this.userAccountDetailsSubscriber=this.pfService.userAccountDetails.subscribe((userDataget) => {
          this.getuserData = userDataget.res.shippingAddresses;
          // this.selectedData = this.getuserData[0];
          this.userAccountDetailsSubscriber?.unsubscribe();
          this.ref.detectChanges();
        });
      }
      this.userAccountDataSubscriber?.unsubscribe();
    });
  }




  productDetails(productDetails) {
    this.pdpService.getPDPCategory(productDetails.product.code);
    this.pdpService.productCode$.subscribe((res) => {
      if (res && res?.code === productDetails.product.code) {
        this.modalService.dismissAll();
       const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent,
       { windowClass: 'product-details-inf-modal' });

       modalRef.componentInstance.productDetails = res;
       modalRef.componentInstance.standardCartCode = res.code;
      }
    });

  }

  OnFluPrint(){
    window.print();
  }
}
