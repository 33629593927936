import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { translationChunksConfig } from '@spartacus/assets';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  ProductAdapter,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CustomProdPgModule } from '../custom-prod-pg/custom-prod-pg.module';
import { customOccProductConfig } from '../shared/config/custom-prod.config';
import { CustomOccProductAdapter } from './custom-occ-product.adapter';
import { CustomPdpComponent } from './custom-pdp/custom-pdp.component';

@NgModule({
  declarations: [CustomPdpComponent],

  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
    SlickCarouselModule,
    NgbModule,
    CustomProdPgModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomPdpComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
    {
      provide: ProductAdapter,
      useClass: CustomOccProductAdapter,
    },
    provideDefaultConfig(customOccProductConfig),
  ],
})
export class CustomPdpModule {}
