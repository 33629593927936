import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';

@Injectable({
  providedIn: 'root'
})
export class CustomAddToCartService {
  cartId: any;
  userId: any;
  public cartDetails = new BehaviorSubject<any>(false);
  cartDetails$ = this.cartDetails.asObservable();
  public checkoutDetails = new BehaviorSubject<any>(false);
  checkoutDetails$ = this.checkoutDetails.asObservable();
  public checkoutFailed = new BehaviorSubject<any>(false);
  checkoutFailed$ = this.checkoutFailed.asObservable();
  public savedCartDetails = new BehaviorSubject<any>(false);
  savedCartDetails$ = this.savedCartDetails.asObservable();
  public cartItems = new BehaviorSubject<any>(false);
  cartItems$ = this.cartItems.asObservable();
  public removedItems = new BehaviorSubject<any>(false);
  removedItems$ = this.removedItems.asObservable();
  public cartData = new BehaviorSubject<any>(false);
  cartData$ = this.cartData.asObservable();
  public emptySessionCart = new BehaviorSubject<any>(false);
  emptySessionCart$ = this.emptySessionCart.asObservable();
  public saveCartName = new Subject<any>();
  public restoredCart = new Subject<any>();
  public updateSessionCart = new Subject<any>();
  public invalidCartName = new BehaviorSubject<any>(false);
  invalidCartName$ = this.invalidCartName.asObservable();
  public invalidOrderName = new BehaviorSubject<any>(false);
  invalidOrderName$ = this.invalidOrderName.asObservable();
  public manageSavedCart = new BehaviorSubject<any>(false);
  manageSavedCart$ = this.manageSavedCart.asObservable();
  public changedLocation = new BehaviorSubject<any>(false);
  changedLocation$ = this.changedLocation.asObservable();
  userAccountData: any;
  userAccountType: any;
  yfEntry = false;
  removeEntriesSub: Subscription;
  userAccountgetData: any;
  cartPage: any;
  public userAccountDetails = new BehaviorSubject<any>(false);
  userAccountDetails$ = this.userAccountDetails.asObservable();
  userAccountdetailsType: any;
  public deleteItems = new BehaviorSubject<any>(false);
  deleteItems$ = this.deleteItems.asObservable();
  public renameSaveCart = new BehaviorSubject<any>(false);
  renameSaveCart$ = this.renameSaveCart.asObservable();
  public saveCartSuccess = new BehaviorSubject<any>(false);
  saveCartSuccess$ = this.saveCartSuccess.asObservable();
  public saveOrderSuccess = new BehaviorSubject<any>(false);
  saveOrderSuccess$ = this.saveOrderSuccess.asObservable();
  public allCartItems = new BehaviorSubject<any>(false);
  allCartItems$ = this.allCartItems.asObservable();
  public createdCart = new BehaviorSubject<any>(false);
  createdCart$ = this.createdCart.asObservable();
  public deleteSavedCartItem = new BehaviorSubject<any>(false);
  deleteSavedCartItem$ = this.deleteSavedCartItem.asObservable();
  public restoreErrored = new BehaviorSubject<any>(false);
  restoreErrored$ = this.restoreErrored.asObservable();
  public updateError = new BehaviorSubject<any>(false);
  updateError$ = this.updateError.asObservable();
  public saveRemovedItems = new BehaviorSubject<any>(false);
  saveRemovedItems$ = this.saveRemovedItems.asObservable();
  constructor(private readonly user: UserAccountFacade,
    private readonly baseService: BaseService, private readonly route: RoutingService,
    private globalMessage: GlobalMessageService,
    public excelService: ExcelDownloadService) {
    this.user.get().subscribe((res: any) => {
      this.userId = res?.userName;
    });
    this.userAccountData = new Subject();
  }
  deleteCartItem(entryNumber, childCartId, cartId): void {
    this.deleteItems = new BehaviorSubject<any>(false);
    this.deleteItems$ = this.deleteItems.asObservable();
    this.baseService.delete(`${config.USER_ACCOUNT}${this.userId}/carts/${cartId}/entries/${entryNumber}/${childCartId}`).subscribe((res) => {
      this.deleteItems.next(true);
    });
  }

  clearCart(childCartId): void {
    this.baseService.delete(`${config.USER_ACCOUNT}${this.userId}/carts/${childCartId}`).subscribe((res) => {
      window.location.reload();
    });
  }
  updateCartDetails(childCartId, entries, cartId): void {
    this.updateError.next(false);
    this.cartDetails = new BehaviorSubject<any>(false);
    this.cartDetails$ = this.cartDetails.asObservable();
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/carts/${cartId}/update/${childCartId}?fields=DEFAULT`, entries).subscribe((res) => {
      if (res) {
        this.cartDetails.next(res);
        this.updateSessionCart.next(cartId);
      }
    }, (error) => {
        this.updateError.next(error)
    });
  }
  getUpdateSessionCart() {
    return this.updateSessionCart.asObservable();
  }
  checkoutCart(checkoutData, cartId): void {
    this.checkoutDetails.next(false);
    this.checkoutFailed.next(false);
    this.baseService.post(`${config.USER_ACCOUNT}${this.userId}/carts/${cartId}/orderSimulate?fields=DEFAULT`, checkoutData).subscribe((res) => {
      if (res) {
        this.checkoutDetails.next(res);
      }
    },
      (err) => {
        // this.getCarts(cartId,this.userId);
        this.checkoutFailed.next(err);
      }
    );
  }

  public getUserAccountDataFromDb(userId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/`, { fields: 'FULL' })
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userAccountType = res.type;
          this.userAccountData.next({ res });
        }
      });
  }
  public getUserAccountDetailsDataFromDb(userId, accountId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/${accountId}`, { fields: 'FULL' })
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userAccountdetailsType = res.type;
          this.userAccountDetails.next({ res });
        }
      });
  }
  saveCartDetails(cartName, cartId): void {
    this.saveCartName.next(false);
    this.invalidCartName.next(false);
    this.saveCartSuccess.next(false);
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/carts/${cartId}/save?fields=DEFAULT&saveCartName=${cartName}`, {})
      .subscribe((res) => {
        if (res) {
          this.saveCartName.next(res);
          this.saveCartSuccess.next(true);
        }
      },
        (err) => {
          this.invalidCartName.next(err);
        }
      );
  }
  saveOrderDetails(cartName, code): void {
    this.saveCartName.next(false);
    this.invalidOrderName.next(false);
    this.saveOrderSuccess.next(false);
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/orders/${code}/save?fields=DEFAULT&saveCartName=${cartName}`, {}).subscribe((res) => {
      if (res) {
        this.saveCartName.next(res);
        this.saveOrderSuccess.next(true);
      }
    },
      (err) => {
        this.invalidOrderName.next(err);
      }
    );
  }
  renameSaveCartDetails(params, savedCartId): void {
    this.invalidCartName.next(false);
    this.renameSaveCart.next(false);
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/carts/${savedCartId}/managesavedcart`, params).subscribe((res) => {
      if (res) {
        this.route.goByUrl('/cart/save');
        this.renameSaveCart.next(res);
      }
    },
      (err) => {
        this.invalidCartName.next(err);
      }
    );
  }
  getUpdateSavedOrder(): Observable<any> {
    return this.saveCartName.asObservable();
  }
  savedOrderDetails(userId): void {
    this.savedCartDetails.next(false);
    this.baseService.get(`${config.USER_ACCOUNT}${userId}/carts?fields=DEFAULT&savedCartsOnly=true`).subscribe((res) => {
      if (res) {
        this.savedCartDetails.next(res);
      }
    });
  }
  savedCartData(savedCartId): void {
    this.baseService.get(`${config.USER_ACCOUNT}${this.userId}/carts/${savedCartId}/savedcart`).subscribe((res) => {

    });
  }
  deleteSavedCart(savedCartId): void {
    this.deleteSavedCartItem.next(false);
    this.baseService.delete(`${config.USER_ACCOUNT}${this.userId}/carts/${savedCartId}/removesavedcart`).subscribe((res) => {
      this.route.goByUrl('/cart/save');
      this.deleteSavedCartItem.next(true);
    });
  }
  restoreSavedCart(savedCartId, sessionCartId): void {
    this.restoredCart.next(false);
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/carts/${sessionCartId}/restoresavedcart?savedCartId=${savedCartId}`, {}).subscribe((res) => {
      this.restoredCart.next(sessionCartId);
    },
      (err) => {
        this.restoreErrored.next(err);
      });
  }
  getRestoredCart() {
    return this.restoredCart.asObservable();
  }
  getCarts(cartId, userId): void {
    if (userId && cartId) {
      this.cartItems.next(false);
      this.baseService.get(`${config.USER_ACCOUNT}${userId}/carts/ssh/${cartId}?fields=DEFAULT`).subscribe((res) => {
        this.cartItems.next(res);
      });
    }
  }
  removedEntries(cartId, userId): void {
    if (userId && cartId) {
      this.removedItems = new BehaviorSubject<any>(false);
      this.removedItems$ = this.removedItems.asObservable();
      this.baseService.get(`${config.USER_ACCOUNT}${userId}/carts/ssh/${cartId}?cartPage=true&fields=DEFAULT&standardCart=true`).subscribe((res) => {
        this.cartPage = true;
        this.removedItems.next(res);
      });
    }
  }
  getSavedCart(savedCartId): void {
    this.baseService.get(`${config.USER_ACCOUNT}${this.userId}/carts/${savedCartId}/savedcart`).subscribe((res) => {
      this.cartData.next(res);
    });
  }
  updateSavedCart(params, savedCartId): void {
    this.manageSavedCart.next(false);
    this.baseService.patch(`${config.USER_ACCOUNT}${this.userId}/carts/${savedCartId}/managesavedcart`, params).subscribe((res) => {
      if (res) {
        this.manageSavedCart.next(true);
      }
    });
  }
  clearSessionCart(stdCartId): void {
    this.baseService.delete(`${config.USER_ACCOUNT}${this.userId}/carts/${stdCartId}`).subscribe((res) => {
      this.emptySessionCart.next(true);
    });
  }
  getAllCarts(userId): void {
    this.allCartItems.next(false);
    if (userId) {
      this.baseService.get(`${config.USER_ACCOUNT}${userId}/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false`).subscribe((res) => {
        this.allCartItems.next(res);
      });
    }
  }
  createCart(userId): void {
    this.baseService.post(`${config.USER_ACCOUNT}${userId}/carts?fields=DEFAULT`, {}).subscribe((res) => {
      this.createdCart.next(res);
    });
  }
  public downloadXls(data, name) {
    this.excelService.exportAsExcelFile(data, name, true);
  }
  saveLocation(shipToUnitId, cartId, userId) {
    this.changedLocation = new BehaviorSubject<any>(false);
    this.changedLocation$ = this.changedLocation.asObservable();
    this.baseService.post(`${config.USER_ACCOUNT}${userId}/carts/${cartId}/addShipToUnit/${shipToUnitId}/true?fields=DEFAULT`, {}).subscribe((res) => {
      this.changedLocation.next(true);
    });
  }
  deleteCartCache() {
    this.allCartItems.next(false);
    this.cartItems.next(false);
    this.userAccountData.next(false);
  }


  removeEntriesMapping() {
    this.removeEntriesSub = this.removedItems$.subscribe(
      (res: any) => {
        if (res) {
          let removedData = res?.removedEntries?.cartModifications;
          let stdCartDetails;
          this.yfEntry = false;
          stdCartDetails = res?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === 'STANDARD'
            );
            if (stdCartDetails && stdCartDetails?.length > 0) {
              stdCartDetails = Object.assign({}, ...stdCartDetails);
              for (let entries of stdCartDetails?.entries) {
                if (entries?.product?.yellowFever) {
                  this.yfEntry = true;
                }
              }
            }
          }
          if (!stdCartDetails?.shipToParty?.yellowFeverFlag && this.yfEntry) {
            this.yfEntry = true;
          } else {
            this.yfEntry = false;
            this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
          }
          if (removedData && removedData?.length > 0) {
            for (const data of removedData) {
              if (
                !stdCartDetails?.shipToParty?.yellowFeverFlag &&
                this.yfEntry
              ) {
                this.globalMessage.add(
                  data.statusMessage,
                  GlobalMessageType.MSG_TYPE_ERROR,
                  5000000
                );
              } else {
                this.globalMessage.add(
                  data.statusMessage,
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }
            }
          }
          this.user.get().subscribe((res) => {
            if (res && res != null) {
              this.userAccountgetData = res;
            }
          }).unsubscribe();
          this.getAllCarts(this.userAccountgetData?.userName);
          this.removedItems.next(false);
        }
      }
    );
  }
}
